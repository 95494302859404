import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import Loader from "../../../components/Loader"
import { ROLES } from "../../../helpers/constants"

export default function ManageUser({onClose,edit,onSubmit,submitting,updateData=null})
{
    const [manageData,setManageData] = useState(edit ? {name:updateData.name,email:updateData.email,active:updateData.active,role:updateData.role} : {name:'',email:'',active:1,role:''})
    const [teams,setTeams] = useState([])
    const formName = 'editUser';
    const [loading,setLoading] = useState(true)
    const [croppedImage,setCroppedImage] = useState()
    const [filteredCities,setFilteredCities] = useState([])
    const [routeWithCities,setRoutesWithCities] = useState([])


useEffect(()=>{
    const fetchData = async () => {
      try {
        // Fetch teams
        setLoading(true)
        const teamsResponse = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/teams`);
        setTeams(teamsResponse.data.data);

        // Fetch cities with routes
        const routesWithCitiesResponse = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/routes-cities`);
        setRoutesWithCities(routesWithCitiesResponse.data.data);

        // Fetch representative info if in edit mode
        if (edit) {
          const repInfoResponse = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative/${edit}`);
          setManageData({
            name: repInfoResponse.data.name,
            email: repInfoResponse.data.email,
            active: parseInt(repInfoResponse.data.active),
            role: repInfoResponse.data.role
          });
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [edit]);



    return <><Popup size="md" submitting={submitting} title={edit ? 'Update User' : 'Create User'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        {!loading ? <form onSubmit={(e)=>onSubmit(e,{...manageData,retailerShopImage:croppedImage})} id={formName} className='block grid-cols-2 gap-y-0 w-full items-start'>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full pr-4  items-start">
            <div>
                <label>Name</label>
                <Input type='text' placeholder={'Enter Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div>
                <label>Email</label>
                <Input type='text' placeholder={'Enter Email Address'} value={manageData.email} setValue={(value)=>setManageData(data=>({...data,email:value}))}/>
            </div>
            <div>
                <label>Role</label>
                <Select placeholder={'Select Role'} options={ROLES} customLabel={'label'} customValue={'value'}  value={manageData.role} setValue={(value)=>setManageData(data=>({...data,role:value}))}/>
            </div>
            <div>
                <label>Status</label>
                <Select placeholder={'Select Status'} options={[{name:'Active',value:1},{name:'Inactive',value:0}]}  value={manageData.active} setValue={(value)=>setManageData(data=>({...data,active:value}))}/>
            </div>
            </div>
        </form> : <div className="max-h-[420px] w-full justify-center items-center"><Loader/></div>}
    </Popup>
    </>
}

