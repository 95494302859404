import React from 'react'
import { Link } from 'react-router-dom'

export default function Header({title,RightContent,parentLink='',parentTitle=''}) {
  return (
    <div className=' px-4 py-4 bg-white flex justify-between items-center border-b  border-b-[#e3e3e3]'>
        <div>
        <p className='text-[12px] font-medium capitalize tracking-[-.25px] text-gray-400'><Link to={'/'}>Home</Link> {parentLink ? '>' : null} <Link className='hover:text-[#2ac08b]' to={`/${parentLink}`}>{parentTitle}</Link></p>
        <h2 className='text-[14px] font-medium capitalize tracking-[-.15px]'>{title}</h2>
        </div>
        <RightContent/>
    </div>
  )
}
