
export default function TabGroup({options,activeTab='orders',setActiveTab}) {
    return (
        <div class={`relative self-stretch h-[full]`}>
            <div className='flex justify-start content-stretch bg-[#f6f6f6] rounded-lg overflow-hidden p-1.5 my-2'>
                {
                    options.map((item,index)=>
                    {
                        return <div key={index} onClick={()=>setActiveTab(item.value)} className={`cursor-pointer  transition-all duration-100 px-6 py-2 relative ${activeTab === item.value ? ' bg-white shadow-sm rounded-md' : ''}`}>
                            <p className={`text-[13px] font-semibold tracking-tight  ${activeTab === item.value ? 'text-[#151515]' : 'text-[#757575]'}`}>{item.label}</p>
                        </div>
                    })
                }
            </div>
        </div>
  )
}