import React from 'react'
import Email from '../images/email.svg'
import Password from '../images/password.svg'

export default function InputGroup({type='email',label,placeholder,required=false,setValue}) {
  return (
    <div className='mt-4 mb-4'>
        <label for="input-group-1">{label}</label>
    <div class="relative mb-6">
    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <img src={type === 'email' ? Email : Password} className='w-4 h-4'/>
    </div>
    <input type={type === 'email' ? 'email' : type ==='password' ? 'password' : 'text'} minLength={type === 'email' ? 4 : 4} id="input-group-1" class=" border border-gray-300 text-gray-900 text-sm rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 block w-full pl-12 p-4 font-medium" 
    placeholder={placeholder} required={required} onChange={(e)=>setValue(e.target.value)}/>
    </div>
    </div>
  )
}
