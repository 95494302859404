import React, { createRef, useState } from 'react'
import CloseIcon from '../images/close.svg'

export default function Popup({title,onClose,children,submitTitle,formName,submitting,size='md'}) {

  return (
    <div className=' fixed z-[999] w-[100%] h-full left-0 top-0 flex items-start overflow-scroll '>
      <div className='flex w-full h-full overflow-hidden justify-end items-center relative  bg-black bg-opacity-70'>
    <div className={`${size === 'xl' ? 'w-[1020px]' : size === 'sm' ? 'w-[580px]' : 'w-[620px]'} max-w-full bg-white  top-0 h-full right-0 rounded-none overflow-hidden  my-4 flex flex-col justify-stretch items-stretch`}>
        <div className='flex px-8 py-4 justify-between items-center border-b-2 border-gray-100 bg-[#fff]'>
            <h3 className='text-[14px] font-medium tracking-[-.15px] capitalize'>{title}</h3>
            <div className='bg-gray-100 px-2 py-2 rounded-md hover:bg-gray-200 transition-all cursor-pointer' onClick={()=>onClose(false)}>
              <img src={CloseIcon} className='w-5 h-5'/>
            </div>
        </div>
        <div className='flex px-8 py-6 w-full h-full overflow-scroll'>
            {children}
        </div>
        <div className='flex justify-end mt-4 px-8 py-4  bg-[#fafafa] border-t-2 border-gray-50'>
            <button type='button' className='btn-md-disabled' onClick={()=>onClose(false)}>Cancel</button>
            <button form={formName} disabled={submitting} type='submit' className='ml-4 btn-md'>{submitTitle}</button>
        </div>
    </div>
      </div>
</div>
  )
}
