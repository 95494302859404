import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
    x: {
        grid: {
          display: false,
        },
      }
  },
  plugins: {
    legend: {
      position: 'top',
      display:false
    },
    style:{
        // border
    }
  },
};



export function RouteOrders({data}) {
  
  let labels = [];
  let items = [];
  
  data.map((item,index)=>
  {
    labels.push(item.routeName)
    items.push(item.orderCount)
  })

    const info = {
      labels,
      datasets: [
        {
          label: 'Orders',
          data: items,
          backgroundColor: '#2980B9',
          borderRadius:4,
        }
      ],
    };
  return <div className='bg-white border border-gray-200 overflow-hidden rounded-md w-full h-[280px] p-4'>
  <p className='text-sm font-medium tracking-tight mb-4'>Orders By Routes</p>
  <div className='w-full h-[220px] m-auto'>
    <Bar options={options} data={info}  />
  </div>
  </div>;
}
