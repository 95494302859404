import ModalImage from "react-modal-image"
import Dot from "../../../components/Dot"
import { getDateFormat, getTimeFormat, getValidDateFormat } from "../../../helpers/utils"

export default function OrderInfo({orderInfo})
    {
        return <div className='block grid-cols-12 flex-1 overflow-scroll bg-white w-full py-10 px-4 gap-x-4'>
        {/* <div className='blockcol-span-3 border-r border-r-gray-200'>
        <p className='uppercase font-bold text-sm tracking-normal text-[#a3a3a3] mb-6'>Order Timeline</p>
        <div className='flex justify-between max-w-[90%] border border-gray-300 rounded-md py-6 px-6'>
            <div className='flex'>
                <Dot color={'#ff0011'}/>
                <div className='ml-4'>
                <p className='text-sm font-medium'>Sales Order Placed</p>
                <p className='text-xs font-regular text-[#757575]'>{`${getValidDateFormat(orderInfo.createdAt)} ${getTimeFormat(orderInfo.createdAt)}`}</p>
                </div>
            </div>
            <div className='flex'>
                <Dot color={'#ff0011'}/>
                <div className='ml-4'>
                <p className='text-sm font-medium'>{orderInfo?.purchaseOrder ? 'Purchase Order Placed' : 'Purchase Order Not Placed'}</p>
                {orderInfo?.purchaseOrder ? <p className='text-xs font-regular text-[#757575]'>{`${getValidDateFormat(orderInfo.purchaseOrder.createdAt)} ${getTimeFormat(orderInfo?.purchaseOrder?.createdAt)}`}</p> : null}
                </div>
            </div>
            <div className='flex'>
                <Dot color={'#ff0011'}/>
                <div className='ml-4'>
                <p className='text-sm font-medium'>Manufacturing Order Created</p>
                <p className='text-xs font-regular text-[#757575]'>{`${getValidDateFormat(orderInfo.productionOrder?.createdAt)} ${getTimeFormat(orderInfo?.productionOrder?.createdAt)}`}</p>
                </div>
            </div>
        </div>
        </div> */}
        <div className='col-span-9'>
    <div className='grid  grid-cols-3 gap-6 w-full border-b border-[#e3e3e3] pb-6 mb-10'>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Order ID</label>
            <p className='text-sm text-black font-medium'>#{orderInfo?.orderId}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>ordered Date</label>
            <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo.orderDate)}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Expected Delivery Date</label>
            <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo?.expectedDeliveryDate)}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Buyer Name</label>
            <p className='text-sm text-black font-medium'>{orderInfo?.buyer?.name}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Contact Person</label>
            <p className='text-sm text-black font-medium'>{orderInfo.buyer?.contactPerson ? orderInfo.buyer.contactPerson : 'Not Available'}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Contact Number</label>
            <p className='text-sm text-black font-medium'>{orderInfo?.buyer?.contactNumber ? orderInfo?.buyer?.contactNumber : 'Not Available'}</p>
        </div>

    </div>

    <div className='bg-white w-full flex-1 flex overflow-scroll mt-10'>
        <table className=' table-auto w-full flex-1 '>
            <thead className='bg-[#f9f9f9] w-full'>
                <tr className='w-full'>
            
                {/* <td><p className='cursor-pointer flex items-center hover:text-black'>Order ID</p></td> */}

                <td><p className='cursor-pointer flex items-center hover:text-black'>Product Name/SKU</p></td>

                <td><p className='cursor-pointer flex items-center hover:text-black'>Cartons/Qty</p></td>
                {/* <td><p className='cursor-pointer flex items-center hover:text-black'>Quantity</p></td> */}

                <td><p className='cursor-pointer flex items-center hover:text-black'>Status</p></td>
                {/* <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td> */}
                {/* <td><p>Action</p></td> */}
                </tr>
            </thead>
            <tbody>
                {
                    orderInfo.orderProducts.map((item,index)=>
                    {
                        return <tr>
                            <td className='capitalize'>
                                <div className="image-preview mr-2 ">
                                    <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.product?.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.product?.image}`} alt={item.product?.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                </div>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.product?.name}</p>
                                    <p className='text-xs text-[#959595] font-medium my-0'>#{item.product?.sku}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.cartons}</p>
                                    <p className='text-xs text-[#959595] font-medium my-0'>{item.quantity}</p>
                                </div>
                            </td>
                            {/* <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.cartons}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.quantity}</p>
                                </div>
                            </td> */}
                            <td className='capitalize'>
                            <div>
                                    <p className={`text-[13px] font-medium px-3 inline-block py-1 rounded-md ${item.status ===  'cancelled' ? 'text-[#454545] bg-gray-200 ' : 'bg-[#39C7A5] bg-opacity-50 text-green-700' } `}>{item.status ===  'cancelled' ? 'Cancelled' : 'Created'}</p>
                                </div>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    </div>

    </div>
        
    </div>
    }