import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"

export default function ManageProduct({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({productSku:'',productName:'',productReward:null,initialStock:12,productIsActive:1,productCategory:'',productImage:'',retailerApproved:true,productTax:'',productHsn:'',productUnit:'9e30f6fe-b861-698d-79f8-fcd6ce6c70a3'})
    const [category,setCategory] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()

    
    const onImageChange = async(event) => {
        if (event.target.files && event.target.files[0]) {
            setManageData(data=>({...data,productImage:null}))
            // setShowCrop(true)
            // console.log(URL.createObjectURL(event.target.files[0]))
            // let blobImage = await dataURItoBlob(event.target.files[0])
            // console.log(blobImage)
            setImage(URL.createObjectURL(event.target.files[0]));
            setCroppedImage(event.target.files[0])
            // setImage(event.target.files[0]);
        }
        }

    
        useEffect(()=>
        {
            async function getCategory(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/category`)
                setCategory(res.data.data)
            }
            async function getHsn(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/hsn`)
                setHsn(res.data.data)
            }
            async function getProductInfo(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product/${edit}`)
                console.log('cate id',res.data.data.productCategory)
                setManageData({productSku:res.data.data.productSku,productName:res.data.data.productName,productReward:res.data.data.productReward,productIsActive:parseInt(res.data.data.productIsActive),productCategory:res.data.data.productCategory,productImage:res.data.data.productImage,retailerApproved:true,productUnit:'9e30f6fe-b861-698d-79f8-fcd6ce6c70a3',productPrice:res.data.data.productPrice,productWeight:res.data.data.productWeight,productTax:res.data.data.productTax,productHsn:res.data.data.productHsn})
            }
            getProductInfo()
            getCategory()
            getHsn()
        },[])





    return <><Popup title={edit ? 'Update Product' : 'Create Product'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData},croppedImage)} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="col-span-2">
                <ImageInput image={manageData.productImage && manageData.productImage!=='' ? `${manageData.productImage}` : image} setImage={onImageChange} />
            </div>
            <div>
                <label>Product Name*</label>
                <Input type='text' placeholder={'Enter Product Name'} required={true} value={manageData.productName} setValue={(value)=>setManageData(data=>({...data,productName:value}))}/>
            </div>
            <div>
                <label>Product SKU*</label>
                <Input type='text' placeholder={'Enter SKU'} required={true} value={manageData.productSku} setValue={(value)=>setManageData(data=>({...data,productSku:value}))}/>
            </div>
            <div>
                <label>HSN</label>
                <Select placeholder={'Select HSN'} required={true} customLabel={'hsnName'} options={hsn} customValue={'id'} value={manageData.productHsn} setValue={(value)=>setManageData(data=>({...data,productHsn:value}))}/>
            </div>
            <div>
                <label>Category</label>
                <Select placeholder={'Select Category'} required={true} customLabel={'categoryName'} options={category} customValue={'id'} value={manageData.productCategory} setValue={(value)=>setManageData(data=>({...data,productCategory:value}))}/>
            </div>
            <div>
                <label>Tax Percentage</label>
                <Input type='text' placeholder={'Enter Tax'} required={true} value={manageData.productTax} setValue={(value)=>setManageData(data=>({...data,productTax:value}))}/>
            </div>
            <div>
                <label>Status</label>
                <Select placeholder={'Select Status'} required={true} options={[{name:'Active',value:1},{name:'Inactive',value:0}]}  value={parseInt(manageData.productIsActive)} setValue={(value)=>setManageData(data=>({...data,productIsActive:value}))}/>
            </div>
            {/* <div>
                <label>Price</label>
                <Input type='text' placeholder={'Enter price'} required={true} value={manageData.productPrice} setValue={(value)=>setManageData(data=>({...data,productPrice:value}))}/>
            </div> */}
            <div>
                <label>Product Weight (In Gms)</label>
                <Input type='text' placeholder={'Product Weight'} required={true} value={manageData.productWeight} setValue={(value)=>setManageData(data=>({...data,productWeight:value}))}/>
            </div>
            <div>
                <label>Reward Points</label>
                <Input type='text' placeholder={'Enter Reward Points'} required={true} value={manageData.productReward} setValue={(value)=>setManageData(data=>({...data,productReward:value}))}/>
            </div>
        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}