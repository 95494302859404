import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import { authAxios } from "../../../helpers/axios"
import SlidePopup from "../../../components/SlidePopup"
import FilterSelect from "../../../components/FilterSelect"

export default function StockFilter({filters,onSubmit,onClose,clearFilter})
{
    const [localFilters,setLocalFilters] = useState({buyerId:null})
    const [buyers,setBuyers] = useState([])
    const [loading,setLoading] = useState(true)


    useEffect(()=>
    {
        async function getBuyers(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/buyer?limit=150&offset=0`)
            setBuyers(res.data.data)
            setLoading(false)
            setLocalFilters(filters)
        }
        getBuyers()
        setLocalFilters(filters)
    },[])

    
    
    return <><SlidePopup title={'BOM Stock Filters'} formName={'bomFilter'} onClose={onClose}>
        {
            loading ?  <p>Loading</p>: 
            <div className="w-full">
            <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                <div>
                    <label>Supplier</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select Buyer'} options={buyers} value={localFilters.buyerId} customLabel={'name'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,buyerId:value}))} padding={false}/>
                </div>
                </div>
                <div className='flex justify-end mt-4  w-full col-span-2'>
                    <button type='button' className='btn-md-disabled' onClick={clearFilter}>Clear Filters</button>
                    <button type='submit' className='ml-4 btn-md' onClick={()=>onSubmit(localFilters)}>Filter Orders</button>
                </div>
            </div>
        </div>
        }
    </SlidePopup>
    </>
}



