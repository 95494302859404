import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import { InfoToast } from '../../helpers/toasters'
import { findCountryByCode } from '../../helpers/utils'
import ManageSupplier from './components/ManageSupplier'
import SupplierOrder from './components/SupplierOrder'
import DeleteSupplierPopup from './components/DeleteSupplierPopup'

export default function BuyerInfo() {

    const {id} = useParams();
    const [orders,setOrders] = useState([])
    const [buyerInfo,setBuyerInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [showDelete,setShowDelete] = useState(false)
    const [activeTab,setActiveTab] = useState('orders')
    const navigate=  useNavigate()
    const [showAddProduct,setShowAddProduct] = useState({status:false,edit:false})

    const RightContent = ()=>
    {
        return <div>
          {activeTab === 'profile' ? <div>

            <button type='button' className='btn-md text-xs font-bold bg-[#b03333] hover:bg-[#b00000] mr-2' onClick={()=>setShowDelete(true)}>Delete Supplier</button>
            <button type='button' className='btn-md text-xs font-bold' onClick={()=>setShowEdit(true)}>Edit Supplier</button>
            </div> : null}
        </div>
    }

    async function getBuyers(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/supplier/${id}?populate=true`)
            setBuyerInfo(res.data)
            // let ordersRes = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?retailerId=${id}&populate=true`)
            // if(ordersRes.data.data) setOrders(ordersRes.data.data)
        } catch (error) {
            toast('Error getting Orders')
        }
    }
    useEffect(()=>
    {
        getBuyers();
    },[])

    const onSubmit = async(e,data,croppedImage)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/supplier/${id}`,data); 
            await getBuyers()
            setShowEdit(false)
            InfoToast('Buyer Updated')
        } catch (error) {
            console.log(error)
            InfoToast(error.response)
        }
    }

    const onDelete = async(e)=>
        {
            try 
            {
                e.preventDefault()
                let res = await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/supplier/${id}`); 
                setShowDelete(false)
                navigate('/suppliers')
                InfoToast('Supplier Deleted')
            } catch (error) {
                InfoToast(error.response)
            }
        }

  return (
    <>
        <Header title={buyerInfo.name} RightContent={RightContent}/>
        <div className=' flex justify-between  border-b border-[#e3e3e3] bg-white'>
            <div className='px-4'>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Orders',value:'orders'},{label:'Profile',value:'profile'}]}/>
            </div>
            <div>

            </div>
        </div>
        <div className='flex flex-1 overflow-scroll bg-white w-full '>
            {activeTab === 'orders' ? <SupplierOrder setShowAddProduct={setShowAddProduct} showAddProduct={showAddProduct} id={id}/> : <ProfileInfo info={buyerInfo}/>}
            
        </div>
        { showEdit ? <ManageSupplier onClose={setShowEdit} onSubmit={onSubmit} edit={true} updateData={buyerInfo}/> : null}
        { showDelete ? <DeleteSupplierPopup onClose={setShowDelete} onSubmit={onDelete} edit={true} updateData={buyerInfo}/> : null}
    </>
  )
}

const ProfileInfo = ({info})=>
{
    
    return <div className='flex justify-start items-start h-[100%] w-full py-12 px-6'>
        <div className='grid grid-cols-3 gap-6 w-full'>
            <div>
                <label>Company Name</label>
                <p className='text-sm font-medium'>{info?.name}</p>
            </div>
            <div>
                <label>Contact Person Name</label>
                <p className='text-sm font-medium'>{info?.contactPerson}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm font-medium'>{info.contactNumber ? info.contactNumber : 'Not Available'}</p>
            </div>
            <div>
                <label>Contact Email</label>
                <p className='text-sm font-medium'>{info.contactEmail ? info.contactEmail : 'Not Available'}</p>
            </div>
            <div>
                <label>GST</label>
                <p className='text-sm font-medium'>{info.gst ? info.gst : 'Not Available'}</p>
            </div>
            <div>
                <label>City</label>
                <p className='text-sm font-medium'>{info?.city}</p>
            </div>
            <div>
                <label>Address</label>
                <p className='text-sm font-medium'>{info?.address}</p>
            </div>
            <div>
                <label>Status</label>
                <p className='text-sm font-medium'>{info?.status === 0 || info?.status === '0' ? 'Active' : 'Inactive'}</p> 
            </div>
        </div>
    </div>
}
