import React, { useEffect, useState } from 'react'
import Logo from '../../images/logo.png'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import InputGroup from '../../components/InputGroup'
// import Toggle from '../../components/Toggle'
import axios from 'axios'
import { authAxios, publicAxios } from '../../helpers/axios'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/slice/authSlice'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'

export default function ResetPassword() {

    const [data,setData] = useState({email:'',tempPassword:'',password:''})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(null)
    const authInfo = useSelector(state=>state.auth)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>
    {
        if(authInfo.token && authInfo.isLoggedIn) return navigate('/')
        
    },[])
    const onSubmit = async(e)=>
    {
        try {
            setError(false)
            e.preventDefault();
            setLoading(true)
            let res = await authAxios.post('/user/reset-password',{email:data.email,password:data.password,tempPass:data.tempPass});
            InfoToast('Password Reset Successfull')
            navigate('/login')
            } catch (error) {
                setLoading(false)
                InfoToast('Email / Temporary Password Incorrect')
            }
            }

            return (
                <div className='flex md:h-screen justify-center'>
        <div className='md:col-span-3 md:h-screen w-full'>
            <div className='h-auto md:h-full w-full flex py-8'>
                <div className="my-auto  mx-auto w-full max-w-md px-12 py-12 rounded-lg bg-white border-gray-100 border">
                    <img src={Logo} className='mx-auto w-auto h-12 mb-2'/>
                    <h1 className='text-xl font-bold text-center'>Reset Password</h1>
                    
                    <form className='mt-12 w-full' onSubmit={onSubmit}>
                        <InputGroup type='email' label="Email" placeholder="Enter Email"  value={data.email} setValue={(value)=>setData(data=>({...data,email:value}))} required={true}/>
                        <InputGroup type='text' label="Temporary Password" placeholder="Enter Temporary Password"  value={data.tempPass} setValue={(value)=>setData(data=>({...data,tempPass:value}))} required={true}/>
                        <InputGroup type='password' label="Confirm Password" placeholder="8+ Characters required"  value={data.password} setValue={(value)=>setData(data=>({...data,password:value}))} required={true}/>
                        <div className='flex justify-between'>
                            <Link to={'/login'} className='text-sm text-blue-700 font-medium'>Remember Password? Login Here</Link>
                        </div>
                        <div className='relative mt-4'>
                            <button type='submit' className='btn-xl w-full mt-4 mb-6' disabled={loading}>{loading ? 'Resetting Password ': 'Reset Password'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
