import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"
import { data } from "../../dashboard/components/PriceData"
import SmallPopup from "../../../components/SmallPopup"

export default function AdjustStock({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({bomId:edit.id,name:edit.name,stock:parseInt(edit.stock)})
    const [category,setCategory] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';


    return <><SmallPopup title={'Update BOM Stock' } submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div>
                <label>BOM Material Name*</label>
                <p className="text-sm ">{manageData.name}</p>
            </div>
            <div>
                <label>BOM Stock</label>
                <Input type='text' placeholder={'Enter Stock'} required={true} value={manageData.stock} setValue={(value)=>setManageData(data=>({...data,stock:value}))}/>
            </div>
        </form>
    </SmallPopup>
    </>
}