import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { useParams } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'
import TabGroup from '../../components/TabGroup'
import OrderFullInfo from './components/OrderFullInfo'
import ProductionInfo from './components/ProductionInfo'
import PurchaseInfo from './components/PurchaseInfo'
import ShowShipped from './components/ShowShipped'
import ShippingPopup from './components/ShippingPopup'
import ModalImage from 'react-modal-image'

export default function ShippingInfo() {

    const {id} = useParams();
    const [orders,setOrders] = useState([])
    const [orderInfo,setOrderInfo] = useState({})
    const [showShip,setShowShip] = useState(false)
    const [showPartialShip,setShowPartialShip] = useState(false)
    const [activeTab,setActiveTab] = useState('order-info')
    const [loading,setLoading] = useState(true)

    const RightContent = ()=>
    {
        return <div>
          {orderInfo.status === 0 || orderInfo.status === 1 ? <button type='button' className='btn-md mr-2' onClick={()=>setShowPartialShip(id,orderInfo)}>Update Shipping</button> : null}
          {orderInfo.status === 0 || orderInfo.status === 1 ? <button type='button' className='btn-md' onClick={()=>setShowShip(id)}>Mark as Shipped</button> : null}
        </div>
    }

    async function getOrderInfo(){

        try 
        {
            let ordersRes = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/shipping/${id}`)
            if(ordersRes.data) setOrderInfo(ordersRes.data)
                setLoading(false)
            } catch (error) {
            setLoading(false)
            ErrorToast('Error getting Orders')
        }
    }
    useEffect(()=>
    {
        getOrderInfo();
    },[])

    const onShippingSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/shipping/update/${id}`,data); 
            await getOrderInfo()
            setShowPartialShip(false)
            InfoToast('Shipping Updated')
        } catch (error) {
            console.log(error)
            InfoToast(error.response)
        }
    }

    const onSubmit = async(e,id)=>
    {
        try 
        {
            e.preventDefault()

            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/shipping/complete/${id}`,{status:1}); 
            await getOrderInfo()
            setShowShip(false)
            InfoToast('Shipping ORder Updated')
        } catch (error) {
            console.log(error)
            InfoToast(error.response)
        }
    }

  return (
    !loading ? <>
        <Header title={`#${orderInfo?.orderId}`} parentLink='/shipping' parentTitle='Shipping Management' RightContent={RightContent}/>
        {/* <div className=' flex justify-between  border-b border-[#e3e3e3] bg-white px-4'>
            <div>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Order Info',value:'order-info'},{label:'Purchase Order Info',value:'purchase-info'},{label:'Production Info',value:'production-info'}]}/>
            </div>
            <div>

            </div>
        </div> */}
        <div className='flex flex-1 overflow-scroll bg-white w-full '>
        <div className='block grid-cols-12 flex-1 overflow-scroll bg-white w-full py-10 px-4 gap-x-4'>
        <div className='col-span-9'>
    <div className='grid  grid-cols-3 gap-6 w-full border-b border-[#e3e3e3] pb-6 mb-10'>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Shipping Order ID</label>
            <p className='text-sm text-black font-medium'>#{orderInfo?.orderId}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Sales Order ID</label>
            <p className='text-sm text-black font-medium'>#{orderInfo?.salesOrder.orderId}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>ordered Date</label>
            <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo.orderDate)}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Expected Delivery Date</label>
            <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo?.expectedDeliveryDate)}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Buyer Name</label>
            <p className='text-sm text-black font-medium'>{orderInfo?.salesOrder.buyer?.name}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Contact Person</label>
            <p className='text-sm text-black font-medium'>{orderInfo.buyer?.contactPerson ? orderInfo.buyer.contactPerson : 'Not Available'}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Contact Number</label>
            <p className='text-sm text-black font-medium'>{orderInfo?.buyer?.contactNumber ? orderInfo?.buyer?.contactNumber : 'Not Available'}</p>
        </div>

    </div>

    <div className='bg-white w-full flex-1 flex overflow-scroll mt-10'>
        <table className=' table-auto w-full flex-1 '>
            <thead className='bg-[#f9f9f9] w-full'>
                <tr className='w-full'>
            
                {/* <td><p className='cursor-pointer flex items-center hover:text-black'>Order ID</p></td> */}

                <td><p className='cursor-pointer flex items-center hover:text-black'>Product Name/SKU</p></td>

                <td><p className='cursor-pointer flex items-center hover:text-black'>Qty Per Carton</p></td>
                <td><p className='cursor-pointer flex items-center hover:text-black'>Qty/Cartons</p></td>
                <td><p className='cursor-pointer flex items-center hover:text-black'>Shipped Quantity</p></td>

                {/* <td><p className='cursor-pointer flex items-center hover:text-black'>Status</p></td> */}
                {/* <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td> */}
                {/* <td><p>Action</p></td> */}
                </tr>
            </thead>
            <tbody>
                {
                    orderInfo.shippingProducts.map((item,index)=>
                    {
                        return <tr>
                            <td className='capitalize'>
                            <div className="image-preview mr-2 ">
                                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.product?.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.product?.image}`} alt={item.product?.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                    </div>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.product?.name}</p>
                                    <p className='text-xs text-[#959595] font-medium my-0'>#{item.product?.sku}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.product?.quantityPerCarton}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.quantity}/{item.cartons}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.shippedCartons}</p>
                                </div>
                            </td>
                            {/* <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.cartons}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                            <div>
                                    <p className='text-sm font-medium my-0'>{item.quantity}</p>
                                </div>
                            </td> */}
                            {/* <td className='capitalize'>
                            <div>
                                    <p className={`text-[13px] font-medium px-3 inline-block py-1 rounded-md ${item.status ===  'cancelled' ? 'text-[#454545] bg-gray-200 ' : 'bg-[#39C7A5] bg-opacity-50 text-green-700' } `}>{item.status ===  'cancelled' ? 'Cancelled' : 'Created'}</p>
                                </div>
                            </td> */}
                        </tr>
                    })
                }
            </tbody>
        </table>
    </div>

    </div>
        
    </div>
            
        </div>

        { showShip ? <ShowShipped onClose={setShowShip} onSubmit={onSubmit} edit={showShip} /> : null}
        { showPartialShip ? <ShippingPopup onClose={setShowPartialShip} onSubmit={onShippingSubmit} orderInfo={orderInfo} edit={showPartialShip}/> : null}
    </> : <Loader/>
  )
}


