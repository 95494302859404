import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"
import { data } from "../../dashboard/components/PriceData"
import SmallPopup from "../../../components/SmallPopup"

export default function UpdateProductionReceiving({onClose,edit=false,onSubmit})
{
    const [manageData,setManageData] = useState({id:edit.id,productId:edit.product.id,finishedCartons:''})
    const [category,setCategory] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';

    console.log('item',edit)
    return <><SmallPopup title={'Update Manufactured Quantity' } submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="mb-4">
                <label>Product Name*</label>
                <p className="text-sm font-medium ">{edit.product.name}</p>
            </div>
            <div>
                <label>Manufactured Cartons</label>
                <Input type='text' placeholder={'Enter Cartons'} required={true} value={manageData.finishedCartons} setValue={(value)=>setManageData(data=>({...data,finishedCartons:value}))}/>
            </div>
        </form>
    </SmallPopup>
    </>
}