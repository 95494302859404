import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import FilterSelect from '../../components/FilterSelect'
import Select from '../../components/Select'
import Map from '../../components/Map'
import { useNavigate } from 'react-router-dom'
import ManageBuyer from './components.jsx/ManageBuyer'
import { getBase64Image } from '../../helpers/helpers'
import DefaultImage from '../../images/image.svg'
import { InfoToast } from '../../helpers/toasters'
import RetailerFilter from './components.jsx/RetailerFilter'
import { LIMIT } from '../../helpers/constants'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import { findCountryByCode } from '../../helpers/utils'

export default function Retailer() {

    const [searchText,setSearchText] = useState('')
    const [retailers,setRetailers] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [exporting,setExporting] = useState(false)
    const navigate = useNavigate()

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])

    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const [sort,setSort] = useState('name');
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }
    
    async function getBuyers(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&searchText=${searchText}`;
                if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
                if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
                if(filters.status) query+=`&status=${filters.status}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/buyer?populate=true${query}`)
            if(res.data.data) 
            {
                setRetailers(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting retailer')
        }
    }
  
    async function exportRetailers(){

        try 
        {
            setExporting(true)
            let query = `populate=true&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
                if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
                if(filters.status) query+=`&status=${filters.status}`
            let response = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/buyer/export?${query}`,{responseType:'blob'})
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a link element
            const link = document.createElement('a');
            link.href = url;

            // Set the filename for the downloaded file
            link.setAttribute('download', 'retailers_export.xlsx');

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Cleanup
            link.parentNode.removeChild(link);
            InfoToast('Retailers Exported')
            setExporting(false)
        } catch (error) {
            setExporting(false)
            console.log(error)
            toast('Error getting retailer')
        }
    }

    useEffect(()=>
    {
        getBuyers()
    },[filters,searchText,offset,sort])

    const RightContent = ()=>
    {
        return <div>
          {/* <button type='button' disabled={exporting} className='btn-md mr-4' onClick={()=>exportRetailers()}>{exporting ? 'Exporting...' : 'Export'}</button> */}
          <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Create Buyer</button>
        </div>
    }

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            // let imageRes;
            // if(croppedImage)
            // {
            //     let formData = new FormData();
            //     formData.append('image',croppedImage)
            //     imageRes = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/image`,formData,{                headers: {
            // 'content-type': 'multipart/form-data'}})
            // } 

            let res;
            if(showCreate.edit)
            {
                // let updateData = croppedImage ? {...data,retailerImage:imageRes.data.data} : {...data};
                InfoToast('Buyer Updated')
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/buyer/${showCreate.edit}`,data); 
            }
            else
            {
                // let createData = croppedImage ? {...data,retailerImage:imageRes.data.data} : {...data};
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/buyer`,data)
                InfoToast('Buyer Created')
            }
                await getBuyers()
                setShowCreate({status:false,edit:null})
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }


  return (
    <>
        <Header title={'Buyers Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
        <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Buyer'} label={'Search Buyer'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
                </div>
                <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        <div className='flex-1 bg-white w-full flex overflow-scroll'>
            <table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Buyer Name{sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('phone')} className='cursor-pointer flex items-center hover:text-black'>Phone/Email{sort === 'phone' || sort === '-phone'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('city')} className='cursor-pointer flex items-center hover:text-black'>City{sort === 'city' || sort === '-city'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('countryCode')} className='cursor-pointer flex items-center hover:text-black'>Country{sort === 'countryCode' || sort === '-countryCode'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status{sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        retailers.map((item,index)=>
                        {
                            return <tr onClick={()=>navigate(`/buyers/${item.id}`)}> 
                                <td className='capitalize'>
                                    <div className='flex'>
                                    {/* <div className='bg-gray-200 rounded-full w-[52px] h-[52px] mr-4'>
                                        <img src={item.retailerImage ? item.retailerImage : DefaultImage} className='w-[52px] h-[52px] rounded-full'/>
                                    </div> */}
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.name}</p>
                                    </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.contactNumber}</p>
                                        <p className='text-[13px] text-gray-400 my-0 lowercase'>{item.contactEmail}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.city}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{findCountryByCode(item.countryCode).name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className={`text-[13px] font-medium px-3 inline-block py-1 rounded-md ${parseInt(item.status) ===  1 ? 'bg-[#39C7A5] bg-opacity-50 text-green-700 ' : 'text-red-700 bg-red-200 bg-opacity-10'}}`}>{parseInt(item.status) ===  1 ? 'Active' : 'Inactive'}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showCreate.status ? <ManageBuyer onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
        { showFilters ? <RetailerFilter onSubmit={onFilterSubmit} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </>
  )
}

