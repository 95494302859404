import ModalImage from "react-modal-image"
import Dot from "../../../components/Dot"
import Progress from "../../../components/Progress"
import { getDateFormat, getTimeFormat, getValidDateFormat } from "../../../helpers/utils"

export default function ProductionInfo({orderInfo})
    {
        return <div className='block grid-cols-12 flex-1 overflow-scroll bg-white w-full py-10 px-4 gap-x-4'>
        <div className='col-span-9'>
    <div className='grid  grid-cols-3 gap-6 w-full border-b border-[#e3e3e3] pb-6 mb-10'>
        {/* <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Order ID</label>
            <p className='text-sm text-black font-medium'>#{orderInfo?.id}</p>
        </div> */}
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>ordered Date</label>
            <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo.orderDate)}</p>
        </div>
        <div>
            <label className='uppercase font-semibold text-[#959595] tracking-normal'>Expected Delivery Date</label>
            <p className='text-sm text-black font-medium'>{orderInfo?.expectedDeliveryDate ? getDateFormat(orderInfo?.expectedDeliveryDate) : 'Not Available'}</p>
        </div>

    </div>

    <div className='bg-white w-full flex-1 flex overflow-scroll mt-10'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    {/* <td><p className='cursor-pointer flex items-center hover:text-black'>SKU</p></td> */}
                
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Name/SKU</p></td>

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Weight</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Total Qty.</p></td>

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Finished Qty.</p></td>
                    
                    </tr>
                </thead>
                <tbody>
                    {
                        orderInfo.productionProducts.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize w-full'>
                                <div className="image-preview mr-2 ">
                                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.product.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.product.image}`} alt={item.product.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                    </div>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.product.name}</p>
                                        <p className='text-[12px] text-[#959595] font-regular my-0'>#{item.product.sku}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.product.weight} Gms</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        {/* <Progress percentage={item.progressPercentage}/> */}
                                    <p className='text-sm font-medium my-0'>{item.quantity}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                    <p className='text-sm font-medium my-0'>{item.finishedQuantity}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
    </div>

    </div>
        
    </div>
    }