import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ManageOrder from '../../order/components/ManageOrder'
import { InfoToast } from '../../../helpers/toasters'
import { LIMIT } from '../../../helpers/constants'
import RightIcon from '../../../images/right.svg'
import SortIcon from '../../../images/sort.svg'
import FilterIcon from '../../../images/filter.svg'
import FilterActiveIcon from '../../../images/filteractive.svg'
import FilterOrder from '../components/DistributorOrderFilter'
import { getDateFormat, getTimeFormat } from '../../../helpers/utils'

export default function DistributorOrder({id}) {

    const [searchText,setSearchText] = useState('')
    const [reps,setOrders] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})
    const [vieworder,setVieworder] = useState(false)
    const [sort,setSort] = useState('-createdOn')

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [summary,setSummary] = useState({})
    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null,salesRepId:null,retailerId:null,startDate:null,endDate:null})
    const [showFilters,setShowFilters] = useState(false)
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Create Order</button>
        </div>
    }

    async function getOrders(){

        try 
        {
            console.log('filters',filters)
            let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}&distributorId=${id}`;
            // query+=``
            if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
            if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
            if(filters.retailerId) query+=`&retailerId=${filters.retailerId.id}`
            if(filters.salesRepId) query+=`&salesRepId=${filters.salesRepId.id}`
            if(filters.teamId) query+=`&salesTeamId=${filters.teamId.id}`
            if(filters.status) query+=`&orderStatus=${filters.status}`
            if(filters.startDate) query+=`&startDate=${`${new Date(filters.startDate).getDate()}-${new Date(filters.startDate).getMonth()+1}-${new Date(filters.startDate).getFullYear()}`}`
            if(filters.endDate) query+=`&endDate=${`${new Date(filters.endDate).getDate()}-${new Date(filters.endDate).getMonth()+1}-${new Date(filters.endDate).getFullYear()}`}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?${query}`)
            console.log('salse',res.data.data)
            if(res.data.data)
            {
                setOrders(res.data.data)
                setCount(res.data.count)
                setSummary(res.data.summary)
                // setOffset(offset+LIMIT)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }



    useEffect(()=>
    {
        getOrders();
    },[offset,searchText,filters,sort])

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCityFilters(res.data.data)
        }
        getCities()
    },[])

    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
            if(res.data.data)  setRouteFilters(res.data.data)
        }
        if(selectedFilters.city !== '') getRoutes()
        else {
            setRouteFilters([]);
            setSelectedFilters(data=>({...data,route:''}))
        }
    },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            // let base64Image = await getBase64Image(data.retailerShopImage)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/orders`,{...data}) 
            if(res.data.data)
            {
                await getOrders()
                toast.info('Sales Rep Added')
                setShowManage({edit:null,status:false})
            }
            else toast('error updating retailer')
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }


    const onOrderCreate = async(e,manageData,products)=>
    {
        try 
        {
            e.preventDefault()
            let orderProducts = products.map((item,index)=>
            {
                if(item.orderQty && item.orderQty > 0 ) return {itemProductId:item.id,itemQty:item.orderQty}
            })
            console.log('orde final products',manageData)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/orders`,{...manageData,orderItems:orderProducts})  
            getOrders()
            setShowManage(false)
            InfoToast('Order Created')
        } catch (error) {
            toast('Order Creating Failed')
            
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }


  return (
    <div className='w-full overflow-scroll flex flex-col'>
    <div>
          <div className=' flex justify-between items-stretch border-b border-[#e3e3e3] bg-white h-full'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search order'} label={'Search order'} />
                <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                    </div>
                    </div>
            </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.distributorId || filters.retailerId || filters.teamId || filters.salesRepId || filters.startDate || filters.endDate) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                
                    <td><p onClick={()=>onSortPress('retailer')} className='cursor-pointer flex items-center hover:text-black'>Retailer {sort === 'retailer' || sort === '-retailer'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('distributor')} className='cursor-pointer flex items-center hover:text-black'>Distributor/Rep {sort === 'distributor' || sort === '-distributor'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('city')} className='cursor-pointer flex items-center hover:text-black'>City {sort === 'city' || sort === '-city'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('createdOn')} className='cursor-pointer flex items-center hover:text-black'>Ordered On {sort === 'createdOn' || sort === '-createdOn'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('bill')} className='cursor-pointer flex items-center hover:text-black'>Bill/Qty {sort === 'bill' || sort === '-bill'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    {/* <td><p>Action</p></td> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        reps.map((item,index)=>
                        {
                            return <tr onClick={()=>setVieworder(item.id)}>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.orderRetailerId.retailerShopName}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{item.orderRetailerId.retailerName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.orderDistributorId.distributorCompanyName}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{item.orderSalesRepId.firstName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.orderRetailerId?.retailerCity?.cityName}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{item.orderRetailerId?.retailerRoute?.routeName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{getDateFormat(item.created_date)}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{getTimeFormat(item.created_date)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                  <p className='text-sm font-regular my-0'>{parseInt(item.orderAmount)}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{parseInt(item.orderQuantity)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className={`text-[13px] font-medium px-3 inline-block py-1 rounded-md ${parseInt(item.orderStatus) ===  1 ? 'text-[#454545] bg-gray-200 ' : parseInt(item.orderStatus) ===  2 ? 'bg-[#39C7A5] bg-opacity-50 text-green-700 ' : 'text-red-700 bg-red-200 bg-opacity-10'}}`}>{parseInt(item.orderStatus) ===  1 ? 'Pending' : parseInt(item.orderStatus) ===  2 ? 'Accepted' : 'Cancelled'}</p>
                                    </div>
                                </td>
                                {/* <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setVieworder(item.id)}>View</button>
                                    </div>
                                </td> */}
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        <div className=' flex justify-between items-stretch border-t border-[#e3e3e3] bg-white w-full'>
            <div className='flex px-6 py-2 justify-between w-full'>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total/Cancelled/Pending/Finished-{count}/{summary.cancelledOrders}/{summary.pendingOrders}/{summary.finishedOrders}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Points - {summary.totalPoints}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Weight - {(summary.totalWeight/1000).toFixed(2)}Kgs</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Amount - {summary.totalAmount}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Qty - {summary.totalQty}</p>
            </div>
        </div>
        { showManage.status ? <ManageOrder onClose={setShowManage} onSubmit={onSubmit} edit={showManage.edit} onOrderCreate={onOrderCreate}/> : null}
        { showFilters ? <FilterOrder onSubmit={onFilterSubmit} id={vieworder} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </div>
  )
}

