import { useState } from "react"
import SmallPopup from "../../../components/SmallPopup"

export default function DeleteBuyerPopup({onClose,edit=false,onSubmit})
{
    const formName = 'deleteBuyer';

    return <><SmallPopup title={'Delete Buyer' } submitTitle={'Submit'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e)} id={formName} className='w-full'>
            <div className="mb-4">
                <p className="text-sm font-medium ">Are you sure you want to change the buyer?</p>
            </div>
        </form>
    </SmallPopup>
    </>
}