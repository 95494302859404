import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"

export default function AddProduct({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState(!edit ? {name:'',sku:'',weight:'',rate:'',boms:[{name:'',quantity:''}]} : {...updateData,name:updateData.name,sku:updateData.sku,weight:updateData.weight,rate:updateData.rate})
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()

    const addBom = () => {
        setManageData((prev) => {
          let prevData = { ...prev, boms: [...prev.boms] };
          prevData.boms.push({ name: '', quantity: '',shared:false });
          return prevData;
        });
      };
      
      const removeBom = (index) => {
        setManageData((prev) => {
          let prevData = { ...prev, boms: [...prev.boms] };
          prevData.boms.splice(index, 1); // Remove item at specific index
          return prevData;
        });
      };
      

    const setItem = (type,index,value)=>
    {
        setManageData((prev)=>
        {
        let newData = {...prev}

        // let newBomData = newData.boms[index]
        if(type === 'name') newData.boms[index].name = value
        if(type === 'quantity') newData.boms[index].quantity = value
        if(type === 'shared') newData.boms[index].shared = value
        if(type === 'rate') newData.boms[index].rate = value

        return newData;
        })

    }

    const onImageChange = async(event) => {
        if (event.target.files && event.target.files[0]) {
            setManageData(data=>({...data,image:null}))
            // setShowCrop(true)
            // console.log(URL.createObjectURL(event.target.files[0]))
            // let blobImage = await dataURItoBlob(event.target.files[0])
            // console.log(blobImage)
            setImage(URL.createObjectURL(event.target.files[0]));
            setCroppedImage(event.target.files[0])
            // setImage(event.target.files[0]);
        }
        }



    return <><Popup title={edit ? 'Update Product' : 'Create Product'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData},croppedImage)} id={formName} className='w-full'>
            <div className="col-span-4">
                    <ImageInput image={manageData.image && manageData.image!=='' ? `${manageData.image}` : image} setImage={onImageChange} />
                </div>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
                <div className="col-span-1">
                    <label>Product Name</label>
                    <Input type='text' placeholder={'Enter Product Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
                </div>
                <div className="col-span-1">
                    <label>Product SKU</label>
                    <Input type='text' placeholder={'Enter SKU'} value={manageData.sku} setValue={(value)=>setManageData(data=>({...data,sku:value}))}/>
                </div>
                <div className="col-span-1">
                    <label>Product Weight (In Grams)</label>
                    <Input type='number' placeholder={'Enter Weight'} value={manageData.weight} setValue={(value)=>setManageData(data=>({...data,weight:value}))}/>
                </div>
                <div className="col-span-1">
                    <label>Quantity (Per Carton)</label>
                    <Input type='number' placeholder={'Enter Quantity'} value={manageData.quantityPerCarton} setValue={(value)=>setManageData(data=>({...data,quantityPerCarton:value}))}/>
                </div>
                {/* <div className="col-span-1">
                    <label>Rate (Per Qty)</label>
                    <Input type='decimal' placeholder={'Enter Rate'} value={manageData.rate} setValue={(value)=>setManageData(data=>({...data,rate:value}))}/>
                </div> */}
            </div>
            {!edit ? <div className="border-t border-t-gray-200 pt-6 my-4">
                <p className="mb-4 font-medium text-sm">Product Bill Of Materials</p>
                {
                    manageData.boms.map((item,index)=>
                {
                    return <BomItem item={item} removeBom={removeBom} addBom={addBom} setItem={setItem} key={index} index={index} length={manageData.boms.length}/>
                })
                }
            </div> : null}
            </div>
        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}


const BomItem = ({item,setItem,index,length,addBom,removeBom})=>
{
    return <div className="flex items-center relative mb-4">
        <div className="grid grid-cols-7 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px] overflow-hidden">
            <div className="col-span-3">
                <input type="text" placeholder="Material Name" className="w-full py-2 px-4 text-[13px] font-medium border-r border-r-gray-300" value={item.name} onChange={(e)=>setItem('name',index,e.target.value)}/>
            </div>
            <div className="col-span-2">
                <select className="w-full py-2 px-4 text-[13px] font-medium focus:shadow-none focus:ring-0 border-0 border-r border-r-gray-200 rounded-none" value={item.shared} onChange={(e)=>setItem('shared',index,e.target.value)}>
                    <option value={false}>Need Qty of</option>
                    <option value={true}>Shared With</option>
                    </select>
            </div>
            <div className="col-span-1 border-r border-r-gray-200">
                <input type="text" placeholder="Qty." className="w-full py-2 px-4 text-[13px] font-medium" value={item.quantity} onChange={(e)=>setItem('quantity',index,e.target.value)}/>
            </div>
            <div className="col-span-1">
                <input type="decimal" placeholder="Rate" className="w-full py-2 px-4 text-[13px] font-medium" value={item.rate} onChange={(e)=>setItem('rate',index,e.target.value)}/>
            </div>
        </div>
        {index+1 === length ? <div className="ml-2 absolute right-0">
            <button type="button" onClick={()=>addBom()}><IoAddCircle fontSize={32}/></button>
        </div> :  <div className="ml-2 absolute right-0"><button type="button" onClick={()=>removeBom(index)}><IoRemoveCircle color="red" fontSize={32}/></button></div>}
    </div>
}