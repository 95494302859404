import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageProduct from './components/ManageProduct'
import { base64ToBlob, dataURItoBlob } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT } from '../../helpers/constants'
import ModalImage from 'react-modal-image'

export default function Product() {

    const [searchText,setSearchText] = useState('')
    const [products,setProducts] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [sort,setSort] = useState('name');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Product</button>
        </div>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getReps(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product?populate=true${query}`)
            if(res.data.data)
            {
                setProducts(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getReps();
    },[sort,searchText,offset])


    const onSubmit = async(e,data,croppedImage)=>
    {
        try 
        {
            e.preventDefault()
            let imageRes
            if(croppedImage)
            {
                let formData = new FormData();
                formData.append('image',croppedImage)
                imageRes = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/image`,formData,{                headers: {
                    'content-type': 'multipart/form-data'
                }}) 
            }

            let res;
            if(showCreate.edit)
            {
                let updateData = croppedImage ? {...data,productImage:imageRes.data.data} : {...data};
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/product/${showCreate.edit}`,updateData) 
                InfoToast('Product Updated')
                
            }
            else
            {
                let createData = croppedImage ? {...data,initialStock:12,productImage:imageRes.data.data} : {...data};
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/product`,createData)
                InfoToast('Product Created')
            }
            if(res.data.data)
            {
                await getReps()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating retailer')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <>
        <Header title={'Product Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
        <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Product'} label={'Search Product'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
                </div>
        </div>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
            <table className='table-fixed w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    {/* <tr className='w-full'>
                    <td><p>Name/SKU</p></td>
                    <td><p>Category</p></td>
                    <td><p>Price(In Rs.)</p></td>
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Reward(In Pts)</p></td>
                    <td><p>Action</p></td>
                </tr> */}
                    <tr className='w-full'>
                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Name/SKU{sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('category')} className='cursor-pointer flex items-center hover:text-black'>Category{sort === 'category' || sort === '-category'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('price')} className='cursor-pointer flex items-center hover:text-black'>Price(In Rs.){sort === 'price' || sort === '-price'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('weight')} className='cursor-pointer flex items-center hover:text-black'>Weight(In Gms){sort === 'weight' || sort === '-weight'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('reward')} className='cursor-pointer flex items-center hover:text-black'>Reward(In Pts){sort === 'reward' || sort === '-reward'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('tax')} className='cursor-pointer flex items-center hover:text-black'>Tax / HSN{sort === 'tax' || sort === '-tax'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        products.map((item,index)=>
                        {
                            return <tr>
                                {/* <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-[52px] h-[52px] rounded-full'/>
                                    </div>
                                </td> */}
                                <td className='capitalize w-[200px]'>
                                <div className='flex items-center align-middle'>
                                    <div>

                                    <div className="image-preview mr-2 ">
                                    <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.productImage}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.productImage}`} alt={item.productName} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                </div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-regular my-0'>{item.productName}</p>
                                            <p className='text-xs my-0 text-gray-400'>{item.productSku}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productCategory?.categoryName ? item.productCategory?.categoryName : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                <div className='align-baseline items-end'><p className='text-sm font-regular my-0'><img src={RupeeIcon} className=' h-[10px] w-[10px] inline'/>{parseFloat(item.productPrice)}</p></div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    {/* <button className='btn-xs' onClick={()=>navigate(`/retailers/${item.id}`)}>View</button> */}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productReward}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productTax}%</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.productHsn?.hsnName ? item.productHsn?.hsnName : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowCreate({status:true,edit:item.id})}>Edit</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </>
  )
}

