import React, { createRef, useState } from 'react'
import CloseIcon from '../images/close.svg'

export default function SlidePopup({title,onClose,children,submitTitle,formName}) {

  return (
    <div className='fixed z-[999] w-[100%] h-full left-0 top-0 flex justify-end items-center overflow-hidden py-2'>
      <div className='bg-black bg-opacity-70 absolute w-full h-full' onClick={()=>onClose(false)}></div>
    <div className='w-[520px] max-w-full bg-white   top-0 right-0 h-[100vh] overflow-scroll relative'>
        <div className='flex px-8 py-4 justify-between items-center border-b-2 border-gray-100 bg-[#fff]'>
            <h3 className='text-[14px] font-medium tracking-[-.15px] capitalize'>{title}</h3>
            <div className='bg-gray-100 px-2 py-2 rounded-md hover:bg-gray-200 transition-all cursor-pointer' onClick={()=>onClose(false)}>
              <img src={CloseIcon} className='w-5 h-5'/>
            </div>
        </div>
        <div className='flex px-8 py-6 w-full '>
            {children}
        </div>
        {/* <div className='flex justify-end mt-4 px-8 py-4  bg-[#fafafa] border-t-2 border-gray-50'>
            <button type='button' className='btn-md-disabled' onClick={()=>onClose(false)}>Cancel</button>
            <button form={formName} type='submit' className='ml-4 btn-md'>{submitTitle}</button>
        </div> */}
    </div>
</div>
  )
}
