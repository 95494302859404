import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Sidebar from './components/Sidebar';
import Login from './pages/auth/Login';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Order from './pages/order/Order';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/dashboard/Dashboard';
import Distributor from './pages/distributor/Distributor';
import Settings from './pages/settings/Settings';
import DistributorInfo from './pages/distributor/DistributorInfo';
import Buyer from './pages/buyers/Buyer';
import BuyerInfo from './pages/buyers/BuyerInfo';
import Product from './pages/products/Product';
import Leaderboard from './pages/leaderboard/Leaderboard';
import Schedule from './pages/schedule/Schedule';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import 'react-tooltip/dist/react-tooltip.css'
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Users from './pages/users/Users';
import UserInfo from './pages/users/UserInfo';
import Supplier from './pages/suppliers/Supplier';
import SupplierInfo from './pages/suppliers/SupplierInfo';
import OrderInfo from './pages/order/OrderInfo';
import Purchase from './pages/purchase/Purchase';
import PurchaseInfo from './pages/purchase/PurchaseInfo';
import ManufacturingOrder from './pages/manufacturing/ManufacturingOrder';
import ManufacturingOrderInfo from './pages/manufacturing/ManufacturingOrderInfo';
import PurchaseStocks from './pages/stocks/PurchaseStocks';
import Shipping from './pages/shipping/Shipping';
import ShippingInfo from './pages/shipping/ShippingInfo';
import ProductStocks from './pages/product stock/ProductStocks';

const router = createBrowserRouter([
  {
    path:'/login',
    element:<Login/>
  },
  {
    path:'/forgot-password',
    element:<ForgotPassword/>
  },
  {
    path:'/reset-password',
    element:<ResetPassword/>
  },
  {
    path:'/',
    element:<Sidebar/>,
    children:[
      {
        path:'/',
        index:true,
        element:<Dashboard/>
        // element:
      },
      {
        path:'sales',
        element:<Order/>
      },
      {
        path:'sales/:id',
        element:<OrderInfo/>
      },
      {
        path:'purchase',
        element:<Purchase/>
      },
      {
        path:'purchase/:id',
        element:<PurchaseInfo/>
      },
      {
        path:'manufacturing',
        element:<ManufacturingOrder/>
      },
      {
        path:'manufacturing/:id',
        element:<ManufacturingOrderInfo/>
      },
      {
        path:'products',
        element:<Product/>
      },
      {
        path:'purchase-stocks',
        element:<PurchaseStocks/>
      },
      {
        path:'product-stocks',
        element:<ProductStocks/>
      },
      {
        path:'leaderboard',
        element:<Leaderboard/>,
      },
      {
        path:'route-schedule',
        element:<Schedule/>,
      },
      {
        path:'users/:id',
        element:<UserInfo/>,
      },
      {
        path:'users',
        element:<Users/>,
      },
      {
        path:'buyers',
        element:<Buyer/>,
      },
      {
        path:'buyers/:id',
        element:<BuyerInfo/>,
      },
      {
        path:'suppliers',
        element:<Supplier/>,
      },
      {
        path:'suppliers/:id',
        element:<SupplierInfo/>,
      },
      {
        path:'shipping',
        element:<Shipping/>,
      },
      {
        path:'shipping/:id',
        element:<ShippingInfo/>,
      },
      {
        path:'distributors',
        element:<Distributor/>,
      },
      {
        path:'distributors/:id',
        element:<DistributorInfo/>,
      },
      {
        path:'settings',
        element:<Settings/>
      },
      // {
      //   path:'/activities'
      // }
    ]
  },
])

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-green-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <RouterProvider router={router}/>
    </PersistGate>
  </Provider>
  <ToastContainer toastClassName={({ type }) => contextClass[type || "default"] +
        " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }/>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
