import React from 'react'

export default function Progress({percentage}) {
  let WidthClass = !percentage ? 'w-0' : `w-[${percentage}%]`;
  return (
    <div>
        <div className={`${percentage < 1 ? 'bg-[#e3e3e3]' : (percentage > 0 && percentage < 100) ? 'bg-[#ff6243] bg-opacity-50' :'009A47 bg-opacity-10' } h-[24px] rounded-sm w-[120px] relative flex`}>
            <div className={`${percentage === 0 ? 'bg-[#e3e3e3]' : percentage > 0 && percentage < 100 ? 'bg-[#ff6243]' : 'bg-[#009A47]' }  h-[24px] rounded-sm`} style={{width:!percentage ? '0%' : `${percentage}%` }}>
                <div className='absolute left-0 top-0 w-full h-full flex items-center justify-center'>
                    <p className={`text-center text-white font-bold text-xs`}>{parseInt(percentage)}%</p>

                </div>
            </div>
        </div>
    </div>
  )
}
