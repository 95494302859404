import React from 'react'
import DefaultImage from '../images/image.svg'

export default function ImageInput({image,setImage,error}) {

  return (
      <div className='pb-5 relative inline-block'>
       <label class="block mb-0">
    <div class={`border cursor-pointer border-gray-100 bg-gray-100 text-gray-900 text-sm rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 px-2 py-1 flex justify-between items-center ${error ? 'border-red-300 focus:border-red-400 focus:ring-red-300' : ''}`}>
        <img src={!image ? DefaultImage : `${process.env.REACT_APP_STATIC_URL}${image}`} className='w-12 h-12 md:w-20 md:h-20 rounded-md'/>
    <input type="file" onChange={setImage}  class="hidden w-full text-sm text-gray-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-md file:border-0 opacity-0
      "/>
      {/* {<p className='text-blue font-medium text-[12px] tracking-[-.35px] text-blue-600 hover:text-blue-800 cursor-pointer'>Upload Logo</p>} */}
  {error ? <div class="w-full absolute left-0 bottom-0 flex items-center pointer-events-none overflow-hidden h-4">
                                <div className='text-center'>
                            <p className='text-red-600 h-[20px] text-[12px] tracking-tight font-medium whitespace-nowrap'>{error}</p>
                                    </div>
                        </div> : null}
  </div>
  </label>
  </div>
  )
}
