import { useState } from "react"
import SmallPopup from "../../../components/SmallPopup"

export default function DeleteSupplierPopup({onClose,edit=false,onSubmit})
{
    const formName = 'deleteSupplier';

    return <><SmallPopup title={'Delete Supplier' } submitTitle={'Submit'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e)} id={formName} className='w-full'>
            <div className="mb-4">
                <p className="text-sm font-medium ">Are you sure you want to delete the buyer?</p>
            </div>
        </form>
    </SmallPopup>
    </>
}